// Generated by Framer (a387266)

import { addFonts, cx, CycleVariantState, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["jzbStFUGn"];

const serializationHash = "framer-tDSnx"

const variantClassNames = {jzbStFUGn: "framer-v-petb8a"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const transformTemplate1 = (_, t) => `translate(-50%, -50%) ${t}`

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "jzbStFUGn", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} className={cx(serializationHash, ...sharedStyleClassNames, "framer-petb8a", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"jzbStFUGn"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "R0Y7SW50ZXIgVGlnaHQtNTAw", "--framer-font-family": "\"Inter Tight\", \"Inter Tight Placeholder\", sans-serif", "--framer-font-size": "18px", "--framer-font-weight": "500", "--framer-letter-spacing": "0.8px", "--framer-line-height": "28px", "--framer-text-color": "var(--extracted-r6o4lv, rgba(29, 29, 31, 0.7))"}}>Blending aesthetics and innovation, my Ui</motion.p><motion.p style={{"--font-selector": "R0Y7SW50ZXIgVGlnaHQtNTAw", "--framer-font-family": "\"Inter Tight\", \"Inter Tight Placeholder\", sans-serif", "--framer-font-size": "18px", "--framer-font-weight": "500", "--framer-letter-spacing": "0.8px", "--framer-line-height": "28px", "--framer-text-color": "var(--extracted-2gxw0f, rgba(29, 29, 31, 0.7))"}}>gives you a top notch clients.</motion.p></React.Fragment>} className={"framer-15i4km1"} data-framer-name={"Body Text"} fonts={["GF;Inter Tight-500"]} layoutDependency={layoutDependency} layoutId={"MY7n2azGF"} style={{"--extracted-2gxw0f": "rgba(29, 29, 31, 0.7)", "--extracted-r6o4lv": "rgba(29, 29, 31, 0.7)"}} transformTemplate={transformTemplate1} verticalAlignment={"top"} withExternalLayout/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-tDSnx.framer-1shisrn, .framer-tDSnx .framer-1shisrn { display: block; }", ".framer-tDSnx.framer-petb8a { height: 56px; overflow: hidden; position: relative; width: 369px; }", ".framer-tDSnx .framer-15i4km1 { flex: none; height: auto; left: 50%; position: absolute; top: 50%; white-space: pre-wrap; width: 369px; word-break: break-word; word-wrap: break-word; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 56
 * @framerIntrinsicWidth 369
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramereNEPHoa30: React.ComponentType<Props> = withCSS(Component, css, "framer-tDSnx") as typeof Component;
export default FramereNEPHoa30;

FramereNEPHoa30.displayName = "Body Text";

FramereNEPHoa30.defaultProps = {height: 56, width: 369};

addFonts(FramereNEPHoa30, [{explicitInter: true, fonts: [{family: "Inter Tight", source: "google", style: "normal", url: "https://fonts.gstatic.com/s/intertight/v7/NGSnv5HMAFg6IuGlBNMjxJEL2VmU3NS7Z2mjPQ-qWSRToK8EPg.woff2", weight: "500"}]}], {supportsExplicitInterCodegen: true})